import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import WizardFiltersCollectionV2 from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Collections/WizardFiltersCollectionV2';
import {
  effects as searchFiltersEffects,
  selectors as searchFiltersSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';

export type Props = {
  onSubmit: () => void;
};

const effects = {
  fireSearchPageSearchCTAEvent: searchPageEffects.fireSearchPageSearchCTAEvent,
  fireSearch: searchPageEffects.fireSearch,
  updateFilters: searchFiltersEffects.updateFilters,
};

const selector = (state) => ({
  isCatOnly: searchFiltersSelectors.getIsCatOnly(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  serviceTypeFilterOptions: searchFiltersSelectors.getServiceTypeFilterOptions(state),
  serviceTypeOptions: searchFiltersSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  isRollout1: searchPageSelectors.getRollout1SimplifiedMobileOwnerSearchForm(state),
});

const WizardFiltersCollectionContainerV2 = (props: Props): JSX.Element => (
  <Connect
    selector={selector}
    effects={effects}
    onMount={() => searchPageEffects.fireAnalyticsEvent(events.SearchOwnerPreferencesDisplayEvent)}
  >
    {(connectedProps) => <WizardFiltersCollectionV2 {...connectedProps} {...props} />}
  </Connect>
);

export default WizardFiltersCollectionContainerV2;
