import React from 'react';
import { Helmet } from 'react-helmet';
import { t } from '@lingui/macro';

import { FLAGS } from '@rover/rsdk/src/modules/Data/Core/FrontendConfiguration';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import useFrontendConfigurationShim from '@rover/shared/js/hooks/useFrontendConfigurationShim';

type Props = {
  serviceTypeSlug: string;
  getServiceTypeName: (arg0: string) => string;
};

const HtmlHeader = ({ serviceTypeSlug, getServiceTypeName }: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeName(serviceTypeSlug);
  const title = i18n._(t`Search For Pet Care In Your Neighborhood | Rover.com`);
  const description = i18n._(
    t`${serviceType} on Rover.com connects you to a nationwide network of dog lovers for hire. Because we believe everyone should experience the love of a dog.`
  );
  const { isFlagActive } = useFrontendConfigurationShim();
  const shouldNoIndex = isFlagActive(FLAGS.ROLLOUT_SEARCH_NOINDEX);
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />

      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="website" />

      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      {shouldNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

HtmlHeader.defaultProps = {
  serviceType: t`Dog Boarding`,
};
export default HtmlHeader;
