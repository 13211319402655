import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import WizardCollection from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Collections/Wizard/WizardCollection';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { selectors as experimentsSelectors } from '@rover/react-lib/src/redux/ducks/experiments.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';
import type { SearchFilters, SearchFiltersOptions, ServiceTypeOption } from '@rover/types';
import { DateRange } from '@rover/types/src/datetime/DateRange';

export type Props = {
  isCatOnly: boolean;
  onChange: (arg0: Partial<SearchFilters>, arg1?: boolean) => void;
  pageType: WizardPageType;
  searchFilters: SearchFilters & { dateRange?: DateRange };
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  locationInputId?: string;
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  isInTimeAloneExperiment: experimentsSelectors.isVariant(state)(
    '14aee4db3dedbdfbf9b66255efe752a439c647b7'
  ),
  isRollout1: searchPageSelectors.getRollout1SimplifiedMobileOwnerSearchForm(state),
  petOptions: petsSelectors.getPets(state),
});

const effects = {
  fireSearchPageCalendarClickEvent: searchPageEffects.fireSearchPageCalendarClickEvent,
};

const WizardCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector} effects={effects}>
    {(connectedProps) => <WizardCollection {...connectedProps} {...props} />}
  </Connect>
);

export default WizardCollectionContainer;
