import React, { FC, useCallback, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Boarding, Daycare, DropIn, Sitting, Walking } from '@rover/icons';
import { Box, Button, Flex, Heading, Text } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import ClickableInput from '@rover/kibble/patterns/ClickableInput';
import { DSTokenMap } from '@rover/kibble/styles';
import FancyRadio from '@rover/react-lib/src/components/formFields/FancyRadioGroup/FancyRadio';
import RowList from '@rover/react-lib/src/components/RowList';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import type { SelectOption } from '@rover/types';

const StyledFancyRadio = styled(FancyRadio)`
  margin-bottom: 0;
  padding-right: 0;
`;

const iconStyles = {
  color: DSTokenMap.TEXT_COLOR_SUCCESS.toString(),
  width: '24px',
  height: '24px',
};

const SERVICE_TYPES_ICONS = {
  [SERVICE_TYPE_CHOICES.boarding]: <Boarding style={iconStyles} />,
  [SERVICE_TYPE_CHOICES.traveling]: <Sitting style={iconStyles} />,
  [SERVICE_TYPE_CHOICES.dropIn]: <Walking style={iconStyles} />,
  [SERVICE_TYPE_CHOICES.dayCare]: <DropIn style={iconStyles} />,
  [SERVICE_TYPE_CHOICES.walking]: <Daycare style={iconStyles} />,
};

const getServiceNameValue = (serviceType: string, serviceTypeOptions: SelectOption[]): string => {
  const option = serviceTypeOptions.find(({ value }) => value === serviceType);
  return `${option?.title} • ${option?.subtitle}`;
};

type SimplifiedMobileOwnerSearchServiceSelectorProps = {
  serviceTypeOptions: SelectOption[];
  searchFilters: {
    serviceType: string;
  };
  onChange: (arg0: { serviceTypeSlug: string }) => void;
};

const SimplifiedMobileOwnerSearchServiceSelector: FC<
  SimplifiedMobileOwnerSearchServiceSelectorProps
> = ({ serviceTypeOptions, searchFilters, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useI18n();

  const serviceTypeValue = useMemo(
    () => getServiceNameValue(searchFilters.serviceType, serviceTypeOptions),
    [searchFilters.serviceType, serviceTypeOptions]
  );

  const handleChange = useCallback(
    (serviceTypeSlug: string): void => {
      const newSelectedServiceType = searchFilters.serviceType !== serviceTypeSlug;

      if (newSelectedServiceType) {
        onChange({
          serviceTypeSlug,
        });
      }
    },
    [searchFilters.serviceType]
  );

  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <ClickableInput inputLabel={i18n._(t`What service?`)} onClick={() => setIsOpen(true)}>
        <Flex alignItems="center">
          <Flex alignItems="center" marginRight="2x">
            {SERVICE_TYPES_ICONS[searchFilters.serviceType]}
          </Flex>
          <Text>{serviceTypeValue}</Text>
        </Flex>
      </ClickableInput>
      <BottomSheet onRequestClose={handleClose} isOpen={isOpen}>
        <Heading fontWeight="semibold" textAlign="center">
          {i18n._(t`What service do you need?`)}
        </Heading>
        <Box width="100%" mt="6x">
          <RowList>
            {serviceTypeOptions.map(({ title, subtitle, value }) => (
              <RowList.Row
                key={value}
                label={
                  <Heading textColor="primary" marginBottom="0x" fontWeight="semibold">
                    {i18n._(title as string)}
                  </Heading>
                }
                sublabel={
                  <Text size="100" textColor="secondary" marginBottom="0x">
                    {i18n._(subtitle as string)}
                  </Text>
                }
                leftItem={SERVICE_TYPES_ICONS[value]}
                onClick={() => handleChange(value)}
                rightItem={
                  <StyledFancyRadio
                    id={value}
                    value={`${searchFilters.serviceType === value}`}
                    checked={searchFilters.serviceType === value}
                    hideLabel
                    label={`${i18n._(title as string)} - ${i18n._(subtitle as string)}`}
                    validationType="inline"
                    name="ServiceType"
                    onChange={() => handleChange(value)}
                    optionWidth="24"
                  />
                }
              />
            ))}
          </RowList>
          <Button variant="primary" size="small" fullWidth mt="6x" onClick={handleClose}>
            {i18n._(t`Save service`)}
          </Button>
        </Box>
      </BottomSheet>
    </>
  );
};

export default SimplifiedMobileOwnerSearchServiceSelector;
