import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import { Box, Button, Heading } from '@rover/kibble/core';
import WizardCollectionContainer from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Collections/Wizard/WizardCollectionContainer';
import { getWizardTitle } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';
import type { SearchFilters, SearchFiltersOptions, ServiceTypeOption } from '@rover/types';

export type Props = {
  i18n: I18nType;
  fireSearchPageSearchCTAEvent: () => void;
  fireSearch: (arg0?: { searchInitiatedByMap: boolean }) => void;
  isCatOnly: boolean;
  onSubmit: () => void;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  updateFilters: (searchFilters: Partial<SearchFilters>) => void;
};

export class WizardFiltersCollectionV2 extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate({
    searchFilters: nextSearchFilters,
    serviceTypeFilterOptions: nextServiceTypeFilterOptions,
    serviceTypeOptions: nextServiceTypeOptions,
  }: Props): boolean {
    const { searchFilters, serviceTypeFilterOptions, serviceTypeOptions } = this.props;

    return (
      JSON.stringify(searchFilters) !== JSON.stringify(nextSearchFilters) ||
      JSON.stringify(serviceTypeFilterOptions) !== JSON.stringify(nextServiceTypeFilterOptions) ||
      JSON.stringify(serviceTypeOptions) !== JSON.stringify(nextServiceTypeOptions)
    );
  }

  handleChange(filters, shouldFireSearch = true): void {
    const { updateFilters, fireSearch } = this.props;
    updateFilters(filters);

    if (shouldFireSearch) {
      fireSearch();
    }
  }

  render(): JSX.Element {
    const {
      i18n,
      isCatOnly,
      onSubmit,
      searchFilters,
      serviceTypeFilterOptions,
      serviceTypeOptions,
      fireSearchPageSearchCTAEvent,
    } = this.props;

    const handleCloseWizard = (): void => {
      fireSearchPageSearchCTAEvent();
      onSubmit();
    };

    return (
      <Box p="4x" pt="0x" borderBottom="1px solid" borderColor="border.primary">
        <Heading size="400" as="h4" pt="6x" mb="8x">
          {i18n._(getWizardTitle(isCatOnly))}
        </Heading>

        <WizardCollectionContainer
          isCatOnly={isCatOnly}
          onChange={this.handleChange}
          pageType={WizardPageType.INLINE}
          searchFilters={searchFilters}
          serviceTypeFilterOptions={serviceTypeFilterOptions}
          serviceTypeOptions={serviceTypeOptions}
        />
        <Button
          data-qa-id="wizard-search-collection-save-button"
          variant="primary"
          size="small"
          fullWidth
          onClick={handleCloseWizard}
          mt="8x"
          mb="12x"
        >
          <Trans>Find available sitters</Trans>
        </Button>
      </Box>
    );
  }
}

const WizardFiltersCollectionV2WithI18n = (props: Omit<Props, 'i18n'>): JSX.Element => {
  const { i18n } = useI18n();

  return <WizardFiltersCollectionV2 {...props} i18n={i18n} />;
};

export default WizardFiltersCollectionV2WithI18n;
