import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';

import {
  effects as searchFiltersEffects,
  selectors as searchFiltersSelectors,
} from './SearchFilters.duck';
import SearchPage from './SearchPage';

const effects = {
  updateFiltersAndFireSearchOnChange: searchPageEffects.updateFiltersAndFireSearchOnChange,
  fireSearchFiltersClickEvent: searchPageEffects.fireSearchFiltersClickEvent,
  updateMobileFilters: searchFiltersEffects.updateFilters,
  fireSearch: searchPageEffects.fireSearch,
};

const selector = (state) => ({
  advancedFiltersCount: searchFiltersSelectors.getAdvancedFiltersCount(state),
  isDateConstrained: searchFiltersSelectors.getIsDateConstrained(state),
  isModalDisabled: searchPageSelectors.getIsModalDisabled(state),
  inVariantSimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getIsInSimplifiedMobileOwnerSearchFormExperiment(state),
  isRollout1SimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getRollout1SimplifiedMobileOwnerSearchForm(state),
  isRollout2SimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getRollout2SimplifiedMobileOwnerSearchForm(state),
  isRollout3SimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getRollout3SimplifiedMobileOwnerSearchForm(state),
  isPetCountersExperiment: searchPageSelectors.getIsInPetCountersExperiment(state),
  hasPets: petsSelectors.getPets(state).length > 0,
});

const SearchPageContainer = (): JSX.Element => {
  return (
    <Connect
      effects={effects}
      selector={selector}
      onMount={[searchPageEffects.fireSearchSearchPageVisitEvent]}
    >
      {(connectedProps) => <SearchPage {...connectedProps} />}
    </Connect>
  );
};

SearchPageContainer.prefetchData = () => searchPageEffects.prefetchDataAndInitialize;

export default SearchPageContainer;
