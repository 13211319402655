import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Box } from '@rover/kibble/core';
import { MQ, SM_MIN } from '@rover/kibble/styles';
import ErrorBoundary from '@rover/react-lib/src/components/errorHandling/ErrorBoundary';
import type { BreakpointMatch } from '@rover/react-lib/src/hooks/useMatchMedia';
import { withMatchMedia } from '@rover/react-lib/src/hooks/useMatchMedia';
import WizardFiltersCollectionContainerV2 from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Collections/WizardFiltersCollectionContainerV2';
import type { SearchResultsObservation } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.types';
import type { SearchFilters } from '@rover/types';

import { getPerfHackIsClosed } from '../../../components/modals/Modal/Modal.common';

import PreventScrollOnLoad from './components/PreventScrollOnLoad';
import {
  AdvancedFiltersButtonFixed,
  AdvancedFiltersModal,
  CondensedFiltersCollection,
  PaginatedWizardModal,
  SearchPageLayout,
  SearchPaginationContainer,
  SearchResults,
  SearchWizardModal,
  WizardFiltersCollection,
} from './components';
import HtmlHeader from './HtmlHeaderContainer';

export type Props = {
  isModalDisabled: boolean;
  advancedFiltersCount: number;
  fireSearchFiltersClickEvent: () => void;
  isDateConstrained: boolean;
  updateFiltersAndFireSearchOnChange: (filters: Partial<SearchFilters>) => void;
  matchesMediaQuery: BreakpointMatch;
  inVariantSimplifiedMobileOwnerSearchFormExperiment: boolean;
  isPetCountersExperiment: boolean;
  hasPets: boolean;
};

type State = {
  scrollToResults: boolean;
  showAdvancedFiltersModal: boolean;
  showPaginatedWizardModal: boolean;
  showWizardFilters: boolean;
  insideSearchResults: boolean;
};

const StyledAdvancedFiltersModal = styled(AdvancedFiltersModal)`
  ${MQ.SM_UP.toString()} {
    display: none;
  }
`;

class SearchPage extends PureComponent<Props, State> {
  state = {
    scrollToResults: false,
    showAdvancedFiltersModal: false,
    // Disable modal in every case if `bypass_interstitial=true` in URL params or if date constrained
    // In PetCountersExperiment, we also disable the modal if the user has pets
    showPaginatedWizardModal:
      !this.props.isModalDisabled &&
      (!this.props.isDateConstrained ||
        (this.props.isPetCountersExperiment && !this.props.hasPets)),
    showWizardFilters: !this.props.isModalDisabled,
    insideSearchResults: false,
  };

  componentDidMount(): void {
    // this makes the state correct if the perf hack closed the modal
    if (getPerfHackIsClosed()) {
      this.closePaginatedWizardModal();
    }
  }

  resultsRef = React.createRef<any>();

  searchCardRefs: HTMLDivElement[] = [];

  handleObserveSearchResults = ({
    viewportIsBelowTop,
    viewportIsAboveBottom,
  }: SearchResultsObservation): void => {
    this.setState({
      insideSearchResults: viewportIsBelowTop && viewportIsAboveBottom,
    });
  };

  openAdvancedFiltersModal = (): void => {
    this.setState(() => ({
      scrollToResults: false,
      showAdvancedFiltersModal: true,
    }));
    this.props.fireSearchFiltersClickEvent();
  };

  closeAdvancedFiltersModal = ({ scrollToResults }: { scrollToResults: boolean }): void => {
    this.setState({
      scrollToResults,
      showAdvancedFiltersModal: false,
      showWizardFilters: false,
    });
  };

  closePaginatedWizardModal = (): void => {
    this.setState(() => ({
      showPaginatedWizardModal: false,
    }));
  };

  handleSaveSearchInformationButtonClick = (): void => {
    this.setState(() => ({
      showWizardFilters: false,
    }));
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    window.scrollTo(0, 0);
  };

  handlePageChange = (page: number): void => {
    const { updateFiltersAndFireSearchOnChange } = this.props;
    const filters = {
      page,
    };
    updateFiltersAndFireSearchOnChange(filters);
    const searchCardRef = this.searchCardRefs[0];
    searchCardRef &&
      searchCardRef.scrollIntoView({
        behavior: 'smooth',
      });
  };

  handleMarkerClick = (index: number): void => {
    const searchCardRef = this.searchCardRefs[index];
    searchCardRef &&
      searchCardRef.scrollIntoView({
        behavior: 'smooth',
      });
  };

  resetResultsScroll = (): void => {
    // the results may get re-mounted during the loading of fresh results and this setTimeout avoids
    // any attempt to scrollIntoView during that re-mounting
    setTimeout(() => {
      const toScroll = this.resultsRef && this.resultsRef.current;

      if (toScroll) {
        toScroll.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 0);
  };

  handleSearchCardRefs = (ref: HTMLDivElement, index: number): void => {
    this.searchCardRefs[index] = ref;
  };

  get shouldShowAdvancedFiltersButton(): boolean {
    return this.state.insideSearchResults;
  }

  renderFiltersCollection = (): JSX.Element => {
    if (this.state.showWizardFilters) {
      return this.props.inVariantSimplifiedMobileOwnerSearchFormExperiment ? (
        <WizardFiltersCollectionContainerV2
          onSubmit={this.handleSaveSearchInformationButtonClick}
        />
      ) : (
        <WizardFiltersCollection onSubmit={this.handleSaveSearchInformationButtonClick} />
      );
    }
    return <CondensedFiltersCollection showAdvancedFiltersModal={this.openAdvancedFiltersModal} />;
  };

  render(): JSX.Element {
    const { advancedFiltersCount, matchesMediaQuery = false } = this.props;

    return (
      <>
        <HtmlHeader />

        {matchesMediaQuery && (
          <ErrorBoundary location="SearchPagePaginatedWizardModal">
            <PreventScrollOnLoad preventScrollOnLoad={this.state.showPaginatedWizardModal} />
            {this.props.isPetCountersExperiment ? (
              <SearchWizardModal
                isOpen={this.state.showPaginatedWizardModal}
                onClose={this.closePaginatedWizardModal}
              />
            ) : (
              <PaginatedWizardModal
                isOpen={this.state.showPaginatedWizardModal}
                onClose={this.closePaginatedWizardModal}
                disablePortal={!this.props.isDateConstrained}
              />
            )}
          </ErrorBoundary>
        )}

        <ErrorBoundary location="SearchPageFilters">
          <Box display={['block', 'none']}>{this.renderFiltersCollection()}</Box>
        </ErrorBoundary>

        <SearchPageLayout
          onMarkerClick={this.handleMarkerClick}
          onScrollableFilterChange={this.resetResultsScroll}
        >
          <SearchResults
            onObserve={this.handleObserveSearchResults}
            scrollToResults={this.state.scrollToResults}
            searchCardRefs={this.handleSearchCardRefs}
            resultsRef={this.resultsRef}
            isSeoPage={false}
            showWizardFilters={this.state.showWizardFilters}
          />
        </SearchPageLayout>

        <ErrorBoundary location="SearchPagePagination">
          <SearchPaginationContainer onPageChange={this.handlePageChange} />
        </ErrorBoundary>

        <ErrorBoundary location="SearchPageAdvancedFilters">
          {this.shouldShowAdvancedFiltersButton && (
            <AdvancedFiltersButtonFixed
              selectedFilterCount={advancedFiltersCount}
              onClick={this.openAdvancedFiltersModal}
            />
          )}
          <StyledAdvancedFiltersModal
            isOpen={this.state.showAdvancedFiltersModal && !this.props.matchesMediaQuery}
            onClose={this.closeAdvancedFiltersModal}
          />
        </ErrorBoundary>
      </>
    );
  }
}

export default withMatchMedia(`(min-width:${SM_MIN}px)`)(SearchPage);
