import React, { useMemo } from 'react';

export type Props = {
  preventScrollOnLoad: boolean;
};

const PREVENT_SCROLL_SCRIPT_STRING = `
<script type="text/javascript">
// adapted from no-scroll lib
function preventScroll(options) {
  if (typeof document === 'undefined') return;
  var doc = document.documentElement;
  scrollTop = window.pageYOffset;
  doc.style.width = '100%';
  doc.style.position = 'fixed';
  doc.style.top = -scrollTop + 'px';
  doc.style.overflow = 'hidden';
}

preventScroll();
</script>
`;

const PreventScrollOnLoad = ({ preventScrollOnLoad }: Props): JSX.Element => {
  // useMemo with an empty array second arg will run only once before render,
  // much like componentWillMount does.
  const markup = useMemo(() => {
    if (preventScrollOnLoad) {
      return PREVENT_SCROLL_SCRIPT_STRING;
    }

    return ''; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  ); // eslint-disable-line react/no-danger
};

export default PreventScrollOnLoad;
