import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { t } from '@lingui/macro';
import get from 'lodash-es/get';

import { SPACE_12X } from '@rover/kibble/tokens/build/es6/DSToken';
import ServiceTypeFilter from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import {
  FilterWrapper,
  Location,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import {
  DayTimeAvailability,
  DaytimeServiceScheduler,
  OvernightDateRange,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import SimplifiedMobileOwnerSearchFormScheduler from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates/SimplifiedMobileOwnerSearchFormScheduler';
import PetCounter from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter';
import {
  getServiceType,
  shouldShowControls,
} from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { PRICE_FILTER } from '@rover/shared/js/constants/searchPage.constants';
import PetTypeFilter from '@rover/shared/js/search/components/PetTypeFilter';
import SimplifiedMobileOwnerSearchPetSelector from '@rover/shared/js/search/components/SimplifiedMobileOwnerSearchPetSelector';
import SimplifiedMobileOwnerSearchServiceSelector from '@rover/shared/js/search/components/SimplifiedMobileOwnerSearchServiceSelector';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type {
  Pet,
  SearchFilters,
  SearchFiltersOptions,
  ServiceType,
  ServiceTypeOption,
} from '@rover/types';
import { DateRange } from '@rover/types/src/datetime/DateRange';
import { PetType } from '@rover/types/src/Pet';
import { ServiceTypeSlug } from '@rover/types/src/ServiceType';

import { SearchPageContext, SearchPageContextProps } from '../../../../utilities/searchPageContext';

export type Props = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  hasPets: boolean;
  isCatOnly: boolean;
  language: string;
  onChange: (arg0: Partial<SearchFilters>, arg1?: boolean) => void;
  pageType: WizardPageType;
  searchFilters: SearchFilters & { dateRange?: DateRange };
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  locationInputId?: string;
  isRollout1?: boolean;
  petOptions: Pet[];
  fireSearchPageCalendarClickEvent: () => void;
};

const WizardCollection: FC<Props> = ({
  getServiceTypeBySlug,
  hasPets,
  isCatOnly,
  language,
  onChange,
  pageType,
  searchFilters,
  serviceTypeFilterOptions,
  serviceTypeOptions,
  locationInputId = 'location-input-wizard',
  isRollout1,
  petOptions,
  fireSearchPageCalendarClickEvent,
}) => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const locationLabel = isRollout1
    ? i18n._(t`${get(serviceType, 'displayName')} near`)
    : i18n._("What's your address or cross-streets?");

  const searchPageContextValue: SearchPageContextProps = useMemo(() => {
    return {
      inVariantSimplifiedMobileOwnerSearchFormExperiment: true,
      isRollout1SimplifiedMobileOwnerSearchFormExperiment: isRollout1,
    };
  }, [isRollout1]);

  const handleChangeDateRange = useCallback(
    (dateRange): void => {
      if (dateRange) {
        onChange(
          {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          },
          dateRange.fireSearch
        );
      }
    },
    [onChange]
  );

  const handleSelectedDaysChange = useCallback(
    (selectedDays): void => {
      onChange({ selectedDays });
    },
    [onChange]
  );

  const startPlaceholder = t`Start date`;
  const endPlaceholder = t`End date`;

  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;
  const handleServiceChange = useCallback(
    ({ serviceTypeSlug }) => {
      onChange({
        maxprice: PRICE_FILTER.DEFAULT_MAX_PRICE,
        minprice: PRICE_FILTER.DEFAULT_MIN_PRICE,
        serviceType: serviceTypeSlug,
      });
    },
    [onChange]
  );

  const handlePetCounterChange = useCallback(
    ({ petType, counter: spacesRequired }) => {
      if (
        petType.length === 1 &&
        petType.includes(PetType.CAT) &&
        searchFilters.serviceType === ServiceTypeSlug.OVERNIGHT_BOARDING
      ) {
        handleServiceChange({ serviceTypeSlug: ServiceTypeSlug.DROP_IN });
      }
      onChange({ petType, spacesRequired });
    },
    [handleServiceChange, onChange, searchFilters.serviceType]
  );

  const handleDateRangeChange = useCallback((frequency) => onChange({ frequency }), [onChange]);

  const handlePetSelectorChange = useCallback(
    ({ pet, petType }: Partial<SearchFilters>) => {
      const localIsCatOnly = getIsCatOnlyPetType(petType as []);
      const newServiceType = getServiceType(
        localIsCatOnly,
        searchFilters.serviceType as ServiceTypeSlug,
        get(serviceType, 'suggestedPetType')
      );
      return onChange({
        pet,
        petType,
        catCare: localIsCatOnly,
        serviceType: newServiceType,
      });
    },
    [onChange]
  );

  const { showPetType, showDateRange, showDaytimeServiceScheduler, showDaytimeAvailability } =
    shouldShowControls({
      hasPets,
      isCatOnly,
      pageType,
      serviceOptions: serviceTypeFilterOptions,
      serviceType: serviceType || undefined,
      serviceTypeSlug: searchFilters.serviceType,
    });

  useEffect(() => {
    if (!isRollout1) return;
    const servicesAndOptionsCompatible = serviceTypeOptions.find(
      ({ value }) => value === searchFilters.serviceType
    );
    if (!servicesAndOptionsCompatible) {
      if (searchFilters.petType?.every((petType) => petType === PetType.CAT)) {
        onChange({ serviceType: ServiceTypeSlug.DROP_IN });
      } else {
        onChange({ serviceType: ServiceTypeSlug.OVERNIGHT_BOARDING });
      }
    }
  }, [searchFilters, serviceTypeOptions, isRollout1]);
  const maxDate = useMemo(() => getMaxRequestDate(), []);

  return (
    <SearchPageContext.Provider value={searchPageContextValue}>
      {isRollout1 && (
        <FilterWrapper>
          {hasPets ? (
            <SimplifiedMobileOwnerSearchPetSelector
              onChange={handlePetSelectorChange}
              petOptions={petOptions}
              searchFilters={searchFilters}
            />
          ) : (
            <PetCounter onChange={handlePetCounterChange} />
          )}
        </FilterWrapper>
      )}

      {showPetType && !isRollout1 && (
        <FilterWrapper>
          <PetTypeFilter
            label={i18n._("I'm looking for service for my:")}
            searchFilters={searchFilters}
            onChange={(petType) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType);
              return onChange({
                petType,
                catCare: localIsCatOnly,
              });
            }}
          />
        </FilterWrapper>
      )}

      {isRollout1 ? (
        <FilterWrapper>
          <SimplifiedMobileOwnerSearchServiceSelector
            serviceTypeOptions={serviceTypeOptions}
            searchFilters={searchFilters}
            onChange={handleServiceChange}
          />
        </FilterWrapper>
      ) : (
        <FilterWrapper>
          <ServiceTypeFilter
            displayMode={pageType === WizardPageType.MODAL ? 'horizontal' : null}
            serviceTypeOptions={serviceTypeOptions}
            label={i18n._('Which service do you need?')}
            searchFilters={searchFilters}
            onChange={onChange}
          />
        </FilterWrapper>
      )}

      <FilterWrapper>
        <Location
          locationInputId={locationInputId}
          label={locationLabel}
          location={searchFilters.location}
          onChange={onChange}
          {...(isRollout1 && { inputHeight: SPACE_12X })}
        />
      </FilterWrapper>

      {/* Render the new service scheduler if rollout 1 is active, otherwise follow the holdout logic with */}
      {/* showDateRange and showDaytimeServiceScheduler. */}
      {isRollout1 && (
        <FilterWrapper>
          <SimplifiedMobileOwnerSearchFormScheduler
            language={language}
            onChange={handleDateRangeChange}
            onDateRangeChange={handleChangeDateRange}
            onSelectedDaysChange={handleSelectedDaysChange}
            onStartDateChange={handleChangeDateRange}
            filters={searchFilters}
            serviceType={serviceType}
            showDateRange={showDateRange}
            emitEvent={fireSearchPageCalendarClickEvent}
            showSubTitle
          />
        </FilterWrapper>
      )}

      {!isRollout1 && showDateRange && (
        <FilterWrapper>
          <OvernightDateRange
            endPlaceholder={endPlaceholder}
            maxDate={maxDate}
            label={i18n._('Which dates do you need?')}
            language={language}
            onChange={handleChangeDateRange}
            searchFilters={searchFilters}
          />
        </FilterWrapper>
      )}

      {!isRollout1 && showDaytimeServiceScheduler && (
        <FilterWrapper>
          <DaytimeServiceScheduler
            displayAs={pageType === WizardPageType.MODAL ? 'compact' : null}
            endPlaceholder={endPlaceholder}
            language={language}
            maxDate={maxDate}
            onChange={(frequency) => onChange({ frequency })}
            onDateRangeChange={handleChangeDateRange}
            oneTimeLabel={i18n._('Which dates do you need?')}
            onSelectedDaysChange={handleSelectedDaysChange}
            onStartDateChange={handleChangeDateRange}
            repeatStartDateLabel={startPlaceholder}
            searchFilters={searchFilters}
            serviceType={serviceType}
            showArrow={false}
            showLabel
            startPlaceholder={startPlaceholder}
          />
        </FilterWrapper>
      )}

      {pageType === WizardPageType.MODAL && showDaytimeAvailability && (
        <FilterWrapper>
          <DayTimeAvailability
            availabilityBuckets={{
              morning: morningAvailability ? morningAvailability.label : null,
              midday: middayAvailability ? middayAvailability.label : null,
              evening: eveningAvailability ? eveningAvailability.label : null,
            }}
            label={i18n._('Which time(s) do you need?')}
            labelIsVisible
            onChange={(availability) => onChange(availability)}
            searchFilters={searchFilters}
          />
        </FilterWrapper>
      )}
    </SearchPageContext.Provider>
  );
};

export default WizardCollection;
