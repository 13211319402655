import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import HtmlHeader from './HtmlHeader';
import { selectors as searchFiltersSelectors } from './SearchFilters.duck';

const selector = (state) => ({
  getServiceTypeName: serviceTypeSelectors.getName(state),
  serviceTypeSlug: searchFiltersSelectors.getFilters(state).serviceType,
});

export default (): JSX.Element => (
  <Connect selector={selector}>{(connectedProps) => <HtmlHeader {...connectedProps} />}</Connect>
);
